import "./batchgeo-invoice-generator/batchgeo-invoice-generator.js";
import "./batchgeo-cookie-notice/batchgeo-cookie-notice.js";
import "./batchgeo-form/batchgeo-form.js";
import "./batchgeo-search/batchgeo-search.js";
import "./batchgeo-signup-page/batchgeo-signup-page-form.js";
import "./batchgeo-signup-page/batchgeo-signup-page.js";
import "./batchgeo-admin-addons/batchgeo-admin-addons.js";
import "./batchgeo-admin-subscription/batchgeo-admin-subscription.js";
import "./batchgeo-admin-map-table/batchgeo-admin-map-table-reducer.js";
import "./batchgeo-admin-map-table/batchgeo-admin-map-table-checkboxes-reducer.js";
import "./batchgeo-inline-notification/batchgeo-inline-notification.js";
import "./batchgeo-my-maps/batchgeo-my-maps.js";
import "./batchgeo-shared-maps/batchgeo-shared-maps.js";
import "./batchgeo-form-errors/batchgeo-form-errors.js";
import "./batchgeo-admin-map-table/batchgeo-admin-map-table.js";
import "./batchgeo-admin-map-table/batchgeo-admin-map-table-loader.js";
import "./batchgeo-admin-map-table/batchgeo-admin-map-table-checkbox.js";
import "./batchgeo-admin-map-table/batchgeo-admin-map-table-checkboxes.js";
import "./batchgeo-admin-map-table/batchgeo-admin-map-table-bulk-action-selectbox.js";
import "./batchgeo-admin-users/batchgeo-admin-users.js";
import "./batchgeo-admin-addons/batchgeo-admin-addons.js";
import "./utilities/3ds-verification-utils.js";
